// VideoCall.jsx
import React from 'react';
import { HMSPrebuilt } from '@100mslive/roomkit-react';

const VideoCall = ({ authToken }) => {
  return (
    <div style={{ 
      position: 'relative',
      width: '100%',
      paddingTop: '56.25%', /* 16:9 Aspect Ratio */
      height: 0,
      maxWidth: '1300px', // Changed from 1280px to 1300px
      margin: '0 auto',
      backgroundColor: '#000' // Optional: better background for video
    }}>
      <div style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '8px', // Optional: rounded corners
        overflow: 'hidden' // Ensure video stays contained
      }}>
        <HMSPrebuilt 
          authToken={authToken}
          options={{
            rememberDeviceSelection: true,
            enableAudio: true,
            enableVideo: true,
            settings: {
              isAudioMuted: false,
              isVideoMuted: false,
            },
            initialView: 'grid',
            onPeerJoin: (peer) => {
              //console.log('Peer joined:', peer);
            },
            onPeerLeave: (peer) => {
              //console.log('Peer left:', peer);
            }
          }}
        />
      </div>
    </div>
  );
};

export default VideoCall;