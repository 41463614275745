import React, {useContext, useEffect, useRef, useState, useCallback} from 'react';
import { useNavigate } from 'react-router-dom';
import { functions } from '../../../../Firebase/firebaseConfig';
import { httpsCallable } from 'firebase/functions';
import MetaTags from "../../../../Components/Common/MetaTags/metaTagsComponent";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';
import { motion } from 'framer-motion';
import { IoEyeOutline } from 'react-icons/io5';
import { CiHeart } from 'react-icons/ci';
// Page meta tags
import { pageMetaTags } from "../../../../Components/Common/MetaTags/metaData";
import MobileSocialShare from '../Components/MobileShareComponent/MobileShareComponent';

import { CiShare2 } from "react-icons/ci";
//Components
import { Likes, Views } from "../Components/LikeViewsComponent/LikeViewsComponent";
import { styles, lightTheme, darkTheme } from "../styles";
import { LoadingContext } from '../../../../Roots/LoadingContent';
import { LoadedGuestSpeakerComponent } from '../Components/GuestSpeakerComponent/GuestSpeakerComponent';
import CommentSection from '../../../../Components/Common/Comments/Comments';
import { LoadedTranscriptComponent } from '../Components/TranscriptComponent/TranscriptComponent';
import { RecommendedComponent } from '../Components/RecommendedComponent/RecommendedComponent';
import QuizPage from '../Components/QuizComponent/QuizPage';
import { emailsHasher } from '../../../../Firebase/educoFunctions';
import {LikeButton} from '../../../../Components/Common/Buttons/LikeButton/LikeButton'

//functions
import useAuth from '../../../../Firebase/Auth';

import { CgTranscript } from "react-icons/cg";

{/* 
  {showQuiz && (
                <div style={styles.quizOverlay}>
                  <QuizPage questions={quizQuestions[currentQuizIndex]} onComplete={handleQuizComplete} />
                </div>
              )}
                useEffect(() => {
    const timer = setInterval(() => {
      const currentTime = videoRef.current?.currentTime || 0;
      const targetTime = quizTimings[currentQuizIndex];

      if (currentTime >= targetTime && !showQuiz) {
        setShowQuiz(true);
        setHasShownQuiz(true);
        videoRef.current.pause();
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [showQuiz, currentQuizIndex]);

  const handleQuizComplete = () => {
    setShowQuiz(false);
    setHasShownQuiz(false);
    if (currentQuizIndex < quizTimings.length - 1) {
      setCurrentQuizIndex(currentQuizIndex + 1);
    }
    if (videoRef.current) {
      videoRef.current.currentTime = quizTimings[currentQuizIndex];
      videoRef.current.play();
    }
  };
                */}

const LoadedContentComponent = ({ data, viewCount, nextVideos}) => {
  const { setIsContentLoaded } = useContext(LoadingContext);
  const [newVideoData, setNewVideoData] = useState(null);
  const videoRef = useRef();
  const [showQuiz, setShowQuiz] = useState(false);
  const [hasShownQuiz, setHasShownQuiz] = useState(false);
  const [currentQuizIndex, setCurrentQuizIndex] = useState(0);
  const [likes, setLikes] = useState(data['likes']);
  const [hasCommented, setHasCommented] = useState(false);
  const [watchPercentage, setWatchPercentage] = useState(0);
  const [lastTrackedPercentage, setLastTrackedPercentage] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [nextVideo, setNextVideo] = useState(null);
  
  const currentUser = useAuth();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);



  function handleVideoEnd() {
    setShowPopup(true);
    //console.log('endeddd');
  }
  //data
  const [videoData, setVideoData] = useState({
      key: data['key'],
      videoSource: data['videoSource'],
      poster: data['pagePoster'],
      alt: data['videoAlt'], 
      likes: data['likes'],
      views: data['count'], 
      title: data['key'],
      pagedesc: data['pageDesc'],
      guestSpeaker: data['guestSpeaker'],
      transcript: data['videoTranscript'], 
    });
  const [updatedLikesCount, setUpdatedLikesCount] = useState(videoData.likes || 0); // State to store updated likes
  const quizTimings = [15, 30, 45, 75, 120];
  const quizQuestions = [
    [
      {
        type: 'multipleChoice',
        question: 'What are stars?',
        options: ['amer', 'semer', 'aimer', 'aider'],
        correctAnswer: 2
      },
      {
        type: 'dragAndDrop',
        question: 'Stars are...',
        correctOrder: ['Aimes', 'tu', 'l\'art', '?'],
        options: ['tu', '?', 'l\'art', 'Aimes']
      }
    ],
    [
      {
        type: 'multipleChoice',
        question: 'to give (something); to hand (something); to give as a present',
        options: ['donner', 'servir', 'offrir', 'donner'],
        correctAnswer: 0
      },
      {
        type: 'dragAndDrop',
        question: 'Do you like food?',
        correctOrder: ['Aimes', 'tu', 'la', 'nourriture', '?'],
        options: ['nourriture', '?', 'Aimes', 'tu', 'la']
      }
    ],
    [
      {
        type: 'multipleChoice',
        question: 'to eat; to have a meal',
        options: ['manger', 'boire', 'aimer', 'aider'],
        correctAnswer: 0
      },
      {
        type: 'multipleChoice',
        question: 'to drink',
        options: ['manger', 'boire', 'aimer', 'aider'],
        correctAnswer: 1
      }
    ]
  ];
  function VideoEndedPopup({ setShowPopup, nextVideo, data }) {
    const [isWatchAgainHovered, setIsWatchAgainHovered] = useState(false);
    const [isNextVideoHovered, setIsNextVideoHovered] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  
    useEffect(() => {
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    let firstVid;
    if (nextVideo) {
      [firstVid] = Object.values(nextVideo);
    }
  
    const videoEndedPopupStyle = {
      height: '100%',
      position: 'absolute',
      left: '0',
      right: '0',
      top: '0',
      bottom: '0',
      marginLeft: windowWidth <= 550 ? 'auto' : '21%', // Adjust left margin for desktop
      marginRight: 'auto',
      marginTop: 'auto',
      marginBottom: 'auto',
      width: windowWidth <= 550 ? '90%' : '50%',
      zIndex: 100,
      color: '#1F484C',
      borderRadius: '14px',
      maxHeight: windowWidth <= 550 ? '200px' : '150px',
      background: '#FEFBF6',
      display: 'flex',
      justifyContent: windowWidth <= 550 ? 'center' : 'flex-start', // Align content to left on desktop
      alignItems: 'center',
      gap: windowWidth <= 550 ? '0.5rem' : '1rem',
      textAlign: 'center',
      padding: windowWidth <= 550 ? '0.8rem' : '1rem',
      border: '2px solid #1F484C',
      boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px',
      flexDirection: windowWidth <= 550 ? 'column' : 'row'
    };
  
    const buttonContainerStyle = {
      display: 'flex',
      flexDirection: windowWidth <= 550 ? 'column' : 'row',
      gap: windowWidth <= 550 ? '1rem' : '3rem',
      width: windowWidth <= 550 ? '100%' : '70%',
      justifyContent: windowWidth <= 550 ? 'center' : 'flex-start', // Align buttons to left on desktop
      marginLeft: windowWidth <= 550 ? '0' : '0.2rem' // Add left margin on desktop
    };
  
    const linkStyle = {
      borderRadius: '14px',
      backgroundColor: '#FEFBF6',
      color: '#1F484C',
      transition: 'all 0.3s ease',
      cursor: 'pointer',
      border: '2px solid #1F484C',
      padding: windowWidth <= 550 ? '0.3rem' : '1rem',
      fontSize: windowWidth <= 550 ? '0.9rem' : '1rem',
      fontWeight: 700,
      textDecoration: 'none',
      width: '100%',
      display: 'block',
      boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px',
    };
  
    const linkHoverStyle = {
      backgroundColor: '#e89919',
      color: '#FEFBF6',
      border: '2px solid #e89919',
      boxShadow: 'none'
    };
  
    const buttonStyle = {
      width: windowWidth <= 550 ? '100%' : '200px', // Fixed width for desktop buttons
    };
  
    return (
      <div style={videoEndedPopupStyle}>
        <div style={buttonContainerStyle}>
          {nextVideo && (
            <div style={buttonStyle}>
              <a
                style={{
                  ...linkStyle,
                  ...(isNextVideoHovered && linkHoverStyle)
                }}
                onMouseEnter={() => setIsNextVideoHovered(true)}
                onMouseLeave={() => setIsNextVideoHovered(false)}
                onClick={() => setShowPopup(false)}
                href={firstVid.videohref}
              >
                Watch Next Video
              </a>
            </div>
          )}
          <div style={buttonStyle}>
            <a
              style={{
                ...linkStyle,
                ...(isWatchAgainHovered && linkHoverStyle)
              }}
              onMouseEnter={() => setIsWatchAgainHovered(true)}
              onMouseLeave={() => setIsWatchAgainHovered(false)}
              onClick={() => setShowPopup(false)}
              href={data.videohref}
            >
              Watch Again
            </a>
          </div>
        </div>
      </div>
    );
  }

  const pageName = data.videohref;
  const canonicalUrl = 'https://offthecurriculum.co.uk/' + pageName;
  const meta = pageMetaTags.Loading;

  const trackVideoInteraction = async (interactionData) => {  
    try {
      //console.log('track video interaction');
      const hashedEmail = emailsHasher(currentUser.email);
      const trackUserInteraction = httpsCallable(functions, 'track_user_interaction');
      //console.log('joseph')
      await trackUserInteraction({
        user_id: hashedEmail,
        interaction_type: 'video',
        video_id: data.key,
        topics: [data.courseTag], // Assuming courseTag is the topic
        ...interactionData
      });
    } catch (error) {
      //console.error('Error tracking video interaction:', error);
    }
  };
  
  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;
  
    if (currentUser) {
      //console.log('currentUser is set:', currentUser);
  
      const handleTimeUpdate = () => {
        const percentage = Math.floor((video.currentTime / video.duration) * 100);
        //console.log(percentage)
  
        // Ensure percentage is exactly 25, 50, 75, or 100 and hasn't been tracked already
        if (
          (percentage === 25 || percentage === 50 || percentage === 75 || percentage === 100) &&
          percentage !== lastTrackedPercentage
        ) {
          //console.log('every 25% interaction');
          trackVideoInteraction({ 
            watch_percentage: percentage,
            liked: false,
            commented: false
           });
          setLastTrackedPercentage(percentage); // Mark this percentage as tracked
        }
      };
  
      video.addEventListener('timeupdate', handleTimeUpdate);
      return () => {
        video.removeEventListener('timeupdate', handleTimeUpdate);
      };
    } else {
      //console.log('currentUser is null or undefined');
    }
  }, [currentUser, lastTrackedPercentage]);
  
  const navigate = useNavigate();

  const handleVideoClick = (newVideoData) => {
    setNewVideoData(newVideoData);
    navigate(window.location.pathname);
  };
  const handleUpdateLikes = (newLikes) => {
    setUpdatedLikesCount(newLikes);
    if (newLikes > likes) {
      trackVideoInteraction({ liked: true });
    }
  };

  useEffect(() => {
    // Force the video element to load the new source
    if (newVideoData) {
      setVideoData(newVideoData);
      if (videoRef.current) {
        videoRef.current.load();
      }
      setNewVideoData(null); 
    }
  }, [newVideoData]);

  
  return (
    <>
      <MetaTags 
        title={videoData['key']}
        description={meta.description}
        url={meta.url}
        image={meta.image}
        siteName={meta.siteName}
        twitterCard={meta.twitterCard}
        twitterSite={meta.twitterSite}
        fbAppId={meta.fbAppId}
      />
      <div style={styles.educoContainer}>
        <div style={{...styles.secondEduco,
          paddingLeft: windowWidth <= 550 ? '0px' : '20px'
        }}>
          <div style={styles.videoContainer}>
          {showPopup && (
            <VideoEndedPopup
              setShowPopup={setShowPopup}
              nextVideo={nextVideos}
              data={data}
            />
          )}
            <video
              id="videoPlayer"
              style={{
                ...styles.videoEduoPlayer, 
                width: windowWidth <= 550 ? '100%' : '96%'}}
              controls
              autoPlay
              controlsList="nodownload"
              poster={data['pagePoster']}
              alt={data['videoAlt']}
              ref={videoRef}
              onContextMenu={(e) => e.preventDefault()}
              onEnded={handleVideoEnd}
              onPlay={() => {
                setShowPopup(false);
              }}
            >
              <source src={videoData['videoSource']} type="video/mp4" />
              <track default kind="captions" srcLang="English" src="" />
            </video>
          </div>
          <div style={styles.videoInfoContainer}>
            <div style={styles.videoAnalytics}>
              <LikeButton name={videoData['key']} initialLikes={likes} onUpdateLikes={handleUpdateLikes} />
              <Likes likes={updatedLikesCount} icon={CiHeart} />
              <Views views={viewCount} icon={IoEyeOutline}/>
              <div>
                <MobileSocialShare canonicalUrl={canonicalUrl}/>
              </div>
            </div>
          <h2>
            {data['key']}
          </h2>
          <p>
            Date released:
          </p>
          <p>
            {data['pageDesc']}
          </p>
          <CommentSection />
          <hr style={styles.educoSubjectCourse}/>
          <h4>
            Guest Speakers
          </h4>
          <LoadedGuestSpeakerComponent data={data['guestSpeaker']} wWidth={windowWidth} />
          <div className="card-course">
          <div className="card-educo-title" style={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            padding: '8px',
            cursor: 'pointer'
          }}>
            <CgTranscript 
              style={{
                color: '#000',
              }} 
              size={20}
            />
            <span>Transcript</span>
          </div>
            <hr 
            style={styles.educoSubjectCourse}
            />
            <LoadedTranscriptComponent data={data['videoTranscript']}/>
          </div>
        </div>
      </div>
      <div style={styles.firstEduco}>
        <h3>For you:</h3>
        <div style={styles.carouselContainer}>
            <div style={styles.carousel}>
                <RecommendedComponent 
                  nextVideos={nextVideos} 
                  onVideoClick={handleVideoClick}
                />
            </div>
        </div>
      </div>
      </div>

    </>
  );
};

export default LoadedContentComponent;

  {/*
  useEffect(() => {
    // Timer for rendering circles
    const timer = setTimeout(() => {
      setIsContentLoaded(true);
    }, 0.1); 
    return () => clearTimeout(timer);
  }, [setIsContentLoaded]);
 */}