import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import useAuth from "../../../Firebase/Auth";
import { emailsHasher } from "../../../Firebase/educoFunctions";
import { functions } from "../../../Firebase/firebaseConfig";
import { httpsCallable } from "firebase/functions";
import { FaAngleDown, FaAngleUp, FaCheck, FaCalendarAlt, FaLock, FaUser, FaProjectDiagram, FaClock, FaGraduationCap } from "react-icons/fa";
import { FaFileWaveform } from "react-icons/fa6";
import ChatWithMentor from "./Components/ChatWithMentor/ChatWithMentor";
import StandardButtonContainer from "../../../Components/Common/Buttons/StandardButtons/StandardButtons";
import BottomLoader from "../../../Components/Common/Loading/BottomLoaderComponent";
import JoinCallButton from "./Components/JoinCall/JoinCallButton/JoinCallButton";

import { useNavigate } from 'react-router-dom';
// metatags
import MetaTags from "../../../Components/Common/MetaTags/metaTagsComponent";
import { pageMetaTags } from "../../../Components/Common/MetaTags/metaData";
import StageProgressionControls from "./StageProgression/StageProgression";
import ActivityLog from "./Components/ActivityLog/ActivityLog";

const DashboardCard = ({ title, icon: Icon, children }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const styles = {
    card: {
      backgroundColor: 'white',
      borderRadius: '0.5rem',
      boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1)',
      padding: '1.5rem',
      flex: 1,
      marginBottom: '1rem',
      transition: "transform 0.2s ease, box-shadow 0.2s ease"
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '1rem'
    },
    icon: {
      color: '#1F484C',
      marginRight: '0.5rem',
      fontSize: '1.25rem'
    },
    title: {
      fontSize: '1.25rem',
      fontWeight: '600',
      color: '#1f2937'
    }
  };

  return (
    <div 
    style={styles.card}
    onMouseEnter={(e) => {
      if (windowWidth > 768) {
        e.currentTarget.style.transform = "translateY(-4px)";
        e.currentTarget.style.boxShadow = "0 12px 20px rgba(0, 0, 0, 0.1)";
      }
    }}
    onMouseLeave={(e) => {
      if (windowWidth > 768) {
        e.currentTarget.style.transform = "translateY(0)";
        e.currentTarget.style.boxShadow = "0 4px 6px rgba(0, 0, 0, 0.05)";
      }
    }}
    >
      <div style={styles.header}>
        <Icon style={styles.icon} />
        <h2 style={styles.title}>{title}</h2>
      </div>
      {children}
    </div>
  );
};

const StatusBadge = ({ status }) => {
  const styles = {
    badge: {
      padding: '0.25rem 0.75rem',
      fontSize: '0.875rem',
      borderRadius: '9999px',
      backgroundColor: '#e0e7ff',
      color: '#4338ca',
      display: 'inline-block'
    }
  };

  return <span style={styles.badge}>{status}</span>;
};

const MentorProgrammePage = () => {
  const meta = pageMetaTags.MentorProgrammePage;
  const { menteeId } = useParams();
  const [userData, setUserData] = useState(null);
  const [userType, setUserType] = useState(null);
  const [menteeEmail, setMenteeEmail] = useState('');
  const [mentorEmail, setMentorEmail] = useState('');
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [epqStage, setEPQStage] = useState();
  const [progressionStatus, setProgressionStatus] = useState('none'); // 'none', 'pending', 'approved'
  const [hasCompletedFeedback, setHasCompletedFeedback] = useState(false);
  const [activities, setActivities] = useState([]);
  const [expandedStages, setExpandedStages] = useState({ 0: true });
  const [isChatOpen, setIsChatOpen] = useState(false);
  const currentUser = useAuth();
  const navigate = useNavigate();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Window resize handler
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const isMobile = windowWidth <= 640;
  const isTablet = windowWidth <= 1024 && windowWidth > 640;

  const styles = {
    container: {
      maxWidth: '80%',
      margin: '0 auto',
      padding: '20px',
    },
    header: {
      marginBottom: '2rem',
    },
    title: {
      fontSize: '1.875rem',
      fontWeight: 'bold',
      color: '#1f2937',
      marginBottom: '0.5rem',
    },
    subtitle: {
      fontSize: '1rem',
      color: '#6b7280',
    },
    cardGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
      gap: '1.5rem',
      marginBottom: '2rem',
    },
    infoRow: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '0.75rem',
    },
    label: {
      color: '#6b7280',
    },
    value: {
      fontWeight: '500',
    },
    bio: {
      marginTop: '0.25rem',
      color: '#374151',
    },
    progressSection: {
      backgroundColor: 'white',
      borderRadius: '0.5rem',
      boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1)',
      padding: '1.5rem',
      marginBottom: '2rem',
    },
    stageContainer: {
      marginBottom: '1rem',
      border: '1px solid #E5E7EB',
      borderRadius: '0.5rem',
      overflow: 'hidden',
    },
    stageHeader: {
      padding: '1rem',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      cursor: 'pointer',
    },
    stageInfo: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.75rem',
    },
    stageNumber: {
      color: '#6b7280',
    },
    stageName: {
      fontWeight: '600',
    },
    stageContent: {
      padding: '1rem',
      backgroundColor: '#F9FAFB',
    },
    buttonContainer: {
      display: 'flex',
      flexDirection: windowWidth <= 640 ? 'row' : 'row',
      gap: windowWidth <= 640 ? '0.5rem' : '1rem',
      marginTop: '1rem',
      flexWrap: 'wrap',
      width: '100%'
    },
    responsiveButton: {
      flex: windowWidth <= 640 ? '1 1 auto' : '0 1 auto',
      minWidth: windowWidth <= 640 ? 'unset' : '120px',
      fontSize: windowWidth <= 640 ? '0.875rem' : '1rem',
      padding: windowWidth <= 640 ? '0.5rem' : '0.75rem',
      whiteSpace: 'nowrap',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
  
    buttonIcon: {
      marginRight: windowWidth <= 640 ? '0.25rem' : '0.5rem',
      fontSize: windowWidth <= 640 ? '0.875rem' : '1rem'
    },
  
    stageContent: {
      padding: windowWidth <= 640 ? '0.75rem' : '1rem',
      backgroundColor: '#F9FAFB',
    },
  };

  const additionalStyles = {
    progressionContainer: {
      marginTop: '1rem',
      borderTop: '1px solid #E5E7EB',
      paddingTop: '1rem'
    }
  };

  useEffect(() => {
    getUserData();
  }, [currentUser, menteeId]);
  useEffect(() => {
    if (userData?.mentorId) {
      checkProgressionRequest();
    }
  }, [userData]);  // This will run whenever userData updates

  const getUserData = async () => {
    if (currentUser) {
      try {
        setIsLoading(true);
        const emailHash = emailsHasher(currentUser.email);
        
        const getMentorMenteeInfo = httpsCallable(functions, 'get_your_mentor_mentee_info');
        const result = await getMentorMenteeInfo({ 
          userId: emailHash,
          menteeId: menteeId,
        });
        
        if (result.data.success) {
          setMenteeEmail(result.data.mentee_email);
          setMentorEmail(result.data.mentor_email);
          setEPQStage(result.data.data.menteeData.stage);
  
          if (result.data.userType === 'mentee'){
            setUserData(result.data.data.menteeData);
            setUserType('mentee');
          } else {
            setUserData(result.data.data.menteeData);
            setUserType('mentor');  
          }
        } else {
          setError(result.data.error || 'Failed to fetch data');
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    } 
  };   
 
  const checkProgressionRequest = async () => {
    if (userData?.mentorId) {
      try {
        const checkRequest = httpsCallable(functions, 'check_progression_request');
        const result = await checkRequest({ 
          data: {
            menteeId: userData?.menteeId, 
            userId: userData?.mentorId,
          }
        });
        //console.log('result here', result)
        if (result.data.success) { 
          setProgressionStatus(result.data.status);
        }
      } catch (error) {
        setError(error.message);
      }
    }
  };


  if (error) return <div style={{ ...styles.container, color: '#dc2626' }}>Error: {error}</div>;

  const stages = [
    {
      name: "Induction",
      formName: 'feedback_001',
      description: "Start building a strong connection with your mentor! Choose a day and time that works best for you to meet with your mentor every week. This will be your dedicated time for guidance, support, and setting your goals."
    },
    {
      name: "Commission",
      formName: 'feedback_002',
      description: "The mentor will discuss the mentees introduction, literature review and method progression from the first session. The mentee will be expected to provide details as to where they could improve the document and the project plan. The mentor will help guide the student to make their project shine!"
    },
    {
      name: "Progression",
      formName: 'feedback_003',
      description: "During the Progression meeting, the student will provide feedback on project advancements. This will be an opportunity for the student to explore things that have been done well or not so well, and how to avoid repeated mistakes and improve for future success."
    },
    {
      name: "Evaluation",
      formName: 'feedback_004',
      description: "For the Evaluation session, students will be expected to have completed their EPQ assessment piece and will have circulated such to their mentor."
    },
    {
      name: "Reflection",
      formName: 'feedback_005',
      description: "The Reflection session will be an opportunity for both the student and mentor to reflect on the assessment piece after the student has made their final amendments (based on the previous sessions feedback), and include any final touches if deemed necessary."
    }
  ];

  const toggleStage = (index) => {
    setExpandedStages((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const handleFeedbackClick = (stageIndex) => {
    const stage = stages[stageIndex];
    //console.log('Full stage object:', stage);
    navigate('/mentors/mentorprogammepage/sessionfeedbackform', {
      state: {
        stage: stageIndex + 1,
        stageName: stage.name,
        formName: stage.formName,
        menteeId: userData?.menteeId, 
        mentorId: userData?.mentorId,
        userType: userType,
      }
    });
  };

  const handleScheduleSession = (menteeEmail, mentorEmail) => {
    //console.log('Emails received:', menteeEmail, mentorEmail);
    
    // Calendar parameters
    const calendarId = 'c_ajsgf1kpvo4mbhldhhsdmhbvhg@group.calendar.google.com';
    const title = 'OTC Mentoring Session';
    const details = 'Schedule your regular mentoring session with your Mentor or Mentee.';
  
    // Only proceed if we have both emails
    if (!menteeEmail || !mentorEmail) {
      //console.error('Missing email addresses:', { menteeEmail, mentorEmail });
      return;
    }
    
    const attendeesEmails = `${menteeEmail},${mentorEmail}`;
    //console.log('Combined emails:', attendeesEmails);
    
    const baseUrl = 'https://calendar.google.com/calendar/render';
    const params = new URLSearchParams({
      action: 'TEMPLATE',
      text: title,
      details: details,
      src: calendarId,
      add: attendeesEmails,
      ctz: 'Europe/London',
      location: 'Google Meet (link will be provided)',
      conf: 'meet'
    });
  
    const calendarUrl = `${baseUrl}?${params.toString()}`;
    window.open(calendarUrl, '_blank');
  };

  // Add these new functions in MentorProgrammePage
const handleProgressionRequest = async () => {
  //console.log('why has it not been called')
  if (currentUser) {
    try {
      setIsLoading(true);
      const requestProgression = httpsCallable(functions, 'request_stage_progression');
      const result = await requestProgression({ 
        userId: emailsHasher(currentUser.email),
        menteeId: userData?.menteeId,
        mentorId: userData?.mentorId,
        currentStage: userData?.stage
      });
      //console.log('result', result)
      if (result.data.success) {
        setHasCompletedFeedback(result.data.completedFeedback)
      } else {
        setError(result.data.error || 'Failed to request progression');
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  } else {
  //console.log('error')
}
}

const handleProgressionApproval = async () => {
  //console.log('handleProgressionApproval running')
  try {
    setIsLoading(true);
    const approveProgression = httpsCallable(functions, 'approve_stage_progression');
    const result = await approveProgression({ 
      menteeId: userData?.menteeId,
      mentorId: userData?.mentorId,
      currentStage: userData?.stage,
    });
    //console.log('approval request result', result)
    if (result.data.success) {
      setProgressionStatus('approved');
      // Update the stages to unlock the next one
      alert('Successfully approved upgrade request!')
    } else {
      setError(result.data.error || 'Failed to approve progression');
    }
  } catch (error) {
    setError(error.message);
  } finally {
    setIsLoading(false);
  }
};

const getStageStatus = (stageName) => {
  if (!userData?.stage) return { locked: true, completed: false };
  
  const stageOrder = {
    "Induction": 0,
    "Commission": 1,
    "Progression": 2,
    "Evaluation": 3,
    "Reflection": 4
  };


  const currentStageIndex = stageOrder[userData.stage];
  //console.log('currentStageIndex', currentStageIndex,userData.stage)
  const thisStageIndex = stageOrder[stageName];
  //console.log('thisStageIndex', thisStageIndex)


  // If we can't find the stage indices, return locked
  if (currentStageIndex === undefined || thisStageIndex === undefined) {
    return { locked: true, completed: false };
  }

  // Stage is completed if current stage is beyond this stage
  const completed = currentStageIndex > thisStageIndex;
  
  // Stage is locked if it's beyond the current stage
  const locked = thisStageIndex > currentStageIndex;

  return { locked, completed };
};

//console.log('userData', userData)

  return (
    <div style={styles.container}>
      <MetaTags 
        title={meta.title}
        description={meta.description}
        url={meta.url}
        image={meta.image}
        siteName={meta.siteName}
        twitterCard={meta.twitterCard}
        twitterSite={meta.twitterSite}
        fbAppId={meta.fbAppId}
      />
      <div style={styles.header}>
        <h1 style={styles.title}>
          {userType === 'mentor' ? `Mentoring ${userData?.name}` : 'Your Mentorship Journey'}
        </h1>
        <p style={styles.subtitle}>
          {userType === 'mentor' 
            ? `Guide ${userData?.name} mentee through their EPQ journey`
            : `Working with ${userData?.mentor} to achieve your goals`}
        </p>
      </div>
      {isLoading ? (
            <>
            <BottomLoader 
              isLoading={true}
              message="Loading your mentor content..."
              backgroundColor={"rgba(255, 255, 255, 0.95)"}
              textColor={"#FFFFFF"}
            />
          </>
        ) : (
          <>
      <div style={styles.cardGrid}>
        <DashboardCard title="Project Information" icon={FaProjectDiagram}>
          <div>
            <div style={styles.infoRow}>
              <span style={styles.label}>Project</span>
              <span style={styles.value}>{userData?.project}</span>
            </div>
            <div style={styles.infoRow}>
              <span style={styles.label}>Current Stage</span>
              <StatusBadge status={userData?.stage} />
            </div>
            <div style={styles.infoRow}>
              <span style={styles.label}>Grade</span>
              <span style={styles.value}>{userData?.grade}</span>
            </div>
            <div style={styles.infoRow}>
              <span style={styles.label}>Last Updated</span>
              <span style={styles.value}>
                {new Date(userData?.lastUpdated).toLocaleDateString()}
              </span>
            </div>
          </div>
        </DashboardCard>

        <DashboardCard 
          title={userType === 'mentor' ? "Mentee Information" : "Mentor Information"} 
          icon={FaUser}
        >
          <div>
            <div style={styles.infoRow}>
              <span style={styles.label}>Name</span>
              <span style={styles.value}>
                {userType === 'mentor' ? userData?.name : userData?.mentor}
              </span>
            </div>
            <div style={{ marginBottom: '0.75rem' }}>
              <span style={styles.label}>Bio</span>
              <p style={styles.bio}>
              {userType === 'mentor' ? userData?.menteeBio : userData?.mentorBio}
              </p>
            </div>
            <div style={styles.infoRow}>
              <span style={styles.label}>ID</span>
              <span style={{ ...styles.value, fontSize: '0.875rem', color: '#6b7280' }}>
                {userType === 'mentor' ? userData?.menteeId : userData?.mentorId}
              </span>
            </div>
          </div>
        </DashboardCard>
      </div>
      <div style={styles.progressSection}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
          <FaGraduationCap style={{ color: '#1F484C', marginRight: '0.5rem', fontSize: '1.25rem' }} />
          <h2 style={{ fontSize: '1.25rem', fontWeight: '600', color: '#1f2937' }}>
            Progress Checkpoints
          </h2>
        </div>

        {stages.map((stage, index) => {
          const stageStatus = getStageStatus(stage.name);
          return (
            <div key={index} style={styles.stageContainer}>
              <div 
                onClick={() => !stageStatus.locked && toggleStage(index)}
                style={{
                  ...styles.stageHeader,
                  backgroundColor: stageStatus.completed ? '#E6F4EA' : (stageStatus.locked ? '#F3F4F6' : 'white'),
                  cursor: stageStatus.locked ? 'default' : 'pointer',
                }}
              >
                <div style={styles.stageInfo}>
                  <span style={styles.stageNumber}>Stage {index + 1}</span>
                  <span style={styles.stageName}>{stage.name}</span>
                  {stageStatus.completed && (
                    <FaCheck style={{ color: '#34D399', marginLeft: '8px' }} />
                  )}
                </div>
                {stageStatus.locked ? (
                  <FaLock style={{ color: '#9CA3AF' }} />
                ) : (
                  expandedStages[index] ? <FaAngleUp /> : <FaAngleDown />
                )}
              </div>
              {expandedStages[index] && !stageStatus.locked && (
                <div style={styles.stageContent}>
                  <p style={{ marginBottom: '1rem' }}>{stage.description}</p>
                  <StandardButtonContainer>
                  <StandardButtonContainer.ButtonTwo 
                    onClick={() => handleScheduleSession(menteeEmail, mentorEmail)}
                    style={{
                      ...styles.responsiveButton,
                      flex: windowWidth <= 640 ? '1' : 'unset'
                    }}
                  >
                    <FaCalendarAlt style={styles.buttonIcon} />
                    {windowWidth <= 640 ? 'Schedule' : 'Schedule Sessions'}
                  </StandardButtonContainer.ButtonTwo>

                  <StandardButtonContainer.ButtonOne
                    onClick={() => handleFeedbackClick(index)}
                    style={{
                      ...styles.responsiveButton,
                      flex: windowWidth <= 640 ? '1' : 'unset'
                    }}
                  >
                    <FaFileWaveform style={styles.buttonIcon} />
                    {windowWidth <= 640 ? 'Feedback' : 'Feedback Form'}
                  </StandardButtonContainer.ButtonOne>
                  <JoinCallButton windowWidth={windowWidth} userType={userType} />
                  </StandardButtonContainer>
                  
                  <div style={additionalStyles.progressionContainer}>
                    <StageProgressionControls 
                      currentStage={stage.name}
                      userType={userType}
                      onRequestProgression={handleProgressionRequest}
                      onApproveProgression={handleProgressionApproval}
                      hasCompletedFeedback={hasCompletedFeedback}
                      progressionStatus={progressionStatus}
                    />
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>

      <ActivityLog 
      activities={activities}
      userType={userType}
      userData={userData}
      //onAddActivity={handleAddActivity}
        />
      
      <div onClick={(e) => e.stopPropagation()}>
        <ChatWithMentor 
          isOpen={isChatOpen} 
          onClick={() => setIsChatOpen(true)}
          userType={userType}
          userData={userData}
        />
      </div>
      </>
        )}
    </div>
  );
};

export default MentorProgrammePage;