import React, {useState, useEffect} from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';

// Non-logged in content
import Home from '../Pages/General/Home';
import About from '../Pages/General/About';
import NotFound from '../Components/Common/NotFound/NotFound';
import ResourceLibrary from '../Pages/General/ResourceLibrary/ResourceLibrary';
import Education from '../Pages/General/Education/Education';
import Volunteer from '../Pages/General/Volunteering/Volunteer';
import BecomeAnOfficer from '../Pages/General/Volunteering/becomeAVolunteer';
import Outreach from '../Pages/General/Outreach/Outreach';
import Donate from '../Pages/General/Donate/Donate';
import Comingsoon from '../Components/Common/ComingSoon/ComingSoon';
// SignInComponent and Registration
import SignInComponent from '../Pages/General/Sign-In-Registration/SignIn';
import ForgotPasswordComponent from '../Pages/General/Sign-In-Registration/ForgotPassword';
import CreateAccountComponent from '../Pages/General/Sign-In-Registration/CreateAccount';
import CreateAUserProfileComponent from '../Pages/EDUCO/CreateAUserProfile/createAUserProfile';
import UploadingProfile from '../Pages/EDUCO/CreateAUserProfile/uploadingProfile';

// Statements
import Statements from '../Pages/General/ResourceLibrary/Statements';
import DiversityStatement from '../Pages/General/About/Statements/diversityInclusionStatement';
import EDICommitmentComponent from '../Pages/General/About/Statements/EDICommitmentComponent';

//firebase fetch request
import { fetchPageRoutes, fetchCommunitiesRoutes, fetchCourseRoutes, fetchMentorsRoutes } from '../Firebase/routeUtils';

// signedIn routes
import ExploreComponent from '../Pages/EDUCO/Explore/Explore';
import CourseExplorePage from '../Pages/EDUCO/Courses/CourseExplorePage';
import CourseVideoPage from '../Pages/EDUCO/Courses/Components/CourseVideoPage';
import CommunitiesExplorePage from '../Pages/EDUCO/Communities/CommunitiesExplorePage';

import MentorsExplorePage from '../Pages/EDUCO/Mentors/MentorsExplorePage';
import MentorProgrammePage from '../Pages/EDUCO/Mentors/MentorProgrammePage';
import StudioDashboard from '../Pages/EDUCO/Studio/StudioDashboard';


// educo routes
import VideoPage from '../Pages/EDUCO/VideoPages/VideoTemplatePage';
import EnrolledCoursePage from '../Pages/EDUCO/Courses/EnrolledCoursePage';
//import QuizResult from '../Pages/EDUCO/Courses/Components/QuizComponent/ResultPage/QuizResult';
import BulkUploader from '../Pages/EDUCO/Studio/BulkUploader/BulkUploader';

import MentorRequestsPage from '../Pages/EDUCO/Mentors/MentorRequestPage';


//import { LoadingContext, LoadingProvider } from './LoadingContent';
//import ProfilePage from '../Pages/EDUCO/Profile/ProfileTemplatePage';

//admin 
import AdminHomepage from '../Pages/Admin/PortalHomepage/AdminHomePage';
import EmailFooterGenerator from '../Pages/Admin/OfficerComponents/CreateFooter/CreateFooter';

import NewsArticle from '../Pages/General/News/newsArticle';

// community routes
//import CreateCommunity from '../Pages/EDUCO/Communities/CreateCommunities';
//import CommunityPage from '../Pages/EDUCO/Communities/Components/CommunityPage';
//import PostPageComponent from '../Pages/EDUCO/Communities/Components/PostPageComponent';
import GrievanceProcedure from '../Pages/General/ResourceLibrary/GrievanceProcess';
import GrievanceForm from '../Pages/General/ResourceLibrary/GrievanceForm';
import MentalHealthCharitiesGuide from '../Pages/General/ResourceLibrary/CharityHelplines';
import WhistleblowingPolicyGuide from '../Pages/General/ResourceLibrary/WhistleblowingPolicyGuide';
import MentoringFeedbackForm from '../Pages/EDUCO/Mentors/MentoringFeedbackForm/MentoringFeedbackForm';
import Enrol from '../Pages/General/Education/Enrol';
import OurTrustees from '../Pages/General/About/MeetTheTrustees/meetTheTrustees';
import OurTeam from '../Pages/General/About/MeetTheTeam/meetTheTeam';
import News from '../Pages/General/News/newspage';
import Policies from '../Pages/General/Policies/PolicyListPage';
import ContactForm from '../Pages/General/ContactUsForm/ContactUsForm';

//import LoadingComponent from '../Components/Common/Loading/LoadingComponent';

import Calendar from '../Pages/EDUCO/Mentors/Calendar/Calendar';

// events 
import EventsPortal from '../Pages/General/Events/eventsMainPage';
import EventsTemplatePage from '../Pages/General/Events/eventsTemplatePage';
import EventRegistrationPage from '../Pages/General/Events/EventRegistrationPage';
const SignedInRoutePages = () => {
    //Firebase page routing... 
    const [educoPageRoutes, setEducoPageRoutes] = useState([]);
    const [communitiesPageRoutes, setCommunitiesPageRoutes] = useState([]);
    const [coursePageRoutes, setCoursePageRoutes] = useState([]);
    const [mentorsPageRoutes, setMentorsPageRoutes] = useState([]);

    useEffect(() => {
        const getRoutes = async () => {
            //fetch calls for firebase routes
            const educoRoutes = await fetchPageRoutes();
            const communityRoutes = await fetchCommunitiesRoutes();
            const mentorRoutes = await fetchMentorsRoutes();
            const courseRoutes = await fetchCourseRoutes();
            
            // setting routes
            setEducoPageRoutes(educoRoutes);
            //console.log('routes', educoRoutes)
            setCommunitiesPageRoutes(communityRoutes);
            setCoursePageRoutes(courseRoutes);
            setMentorsPageRoutes(mentorRoutes);
        };
        getRoutes(); 
    }, []);
    const [communities, setCommunities] = useState([]);
    const navigate = useNavigate();
  
    const handleCreateCommunity = (community) => {
      setCommunities([...communities, community]);
      navigate(`/community/${community.title}`);
    };
    
    //console.log(educoPageRoutes)

  return (
    // Update your routes to include unique keys
<Routes>
  <Route key="home" path="/" element={<Home />} />
  <Route key="about" path="/about" element={<About />} />
  <Route key="meet-team" path="/meettheteam" element={<About />} />
  <Route key="trustees" path="/ourtrustees" element={<OurTrustees />} />
  <Route key="team" path="/ourteam" element={<OurTeam />} />
  <Route key="contact" path="/contactus" element={<ContactForm />} />
  <Route key="donate" path="/donate" element={<Donate />} />
  <Route key="events-portal" path="/events" element={<EventsPortal />}/>
  <Route key="events-template" path="/events/:eventId" element={<EventsTemplatePage />} />
  <Route key="events-register" path="/events/register/:eventId" element={<EventRegistrationPage />}/>
  <Route key="education" path="/education" element={<Education />} />
  <Route key="enrol" path="/education/enrol" element={<Enrol />} />
  <Route key="volunteer" path="/volunteer" element={<Volunteer />} />
  <Route key="become-officer" path="/volunteer/becomeanofficer" element={<BecomeAnOfficer />} />
  <Route key="outreach" path="/outreach" element={<Outreach />} />
  <Route key="policies" path="/policies" element={<Policies />} />
  <Route key="policy-id" path="/policies/:policyId" element={<Policies />} />
  <Route key="statements" path="/statements" element={<Statements />} />
  <Route key="diversity-statement" path="/statements/resourceLibrary/diversitystatement" element={<DiversityStatement />} />
  <Route key="edi-statement" path="/statements/resourceLibrary/edistatement" element={<EDICommitmentComponent />} />
  <Route key="resource-library" path="/statements/resourceLibrary" element={<ResourceLibrary />} />
  <Route key="grievance-procedure" path="/statements/resourcelibrary/grievanceprocedure" element={<GrievanceProcedure />} />
  <Route key="grievance-form" path="/statements/resourcelibrary/grievanceform" element={<GrievanceForm />} />
  <Route key="charity-helplines" path="/statements/resourcelibrary/charityhelplines" element={<MentalHealthCharitiesGuide />} />
  <Route key="whistleblowing" path="/statements/resourcelibrary/whistleblowingpolicyguide" element={<WhistleblowingPolicyGuide />} />
  <Route key="sign-in" path="/signIn" element={<SignInComponent />} />
  <Route key="forgot-password" path="/forgotPassword" element={<ForgotPasswordComponent />} />
  <Route key="create-account" path="/createaccount" element={<CreateAccountComponent />} />
  <Route key="create-profile" path="/createauserprofile" element={<CreateAUserProfileComponent />} />
  <Route key="upload-profile" path="/uploadingprofile" element={<UploadingProfile />} />
  <Route key="explore" path="/explore" element={<ExploreComponent />} />
  <Route key="educo" path="/educo" element={<Comingsoon />}/> 
  <Route key="profile" path="/profile/*" element={<Comingsoon />} />

  <Route key="courses" path="/courses" element={<Comingsoon />} />
  <Route key="course-id" path="/course/:courseId" element={<Comingsoon />} />

  {/*
  <Route key="courses" path="/courses" element={<CourseExplorePage />} />
  <Route key="course-id" path="/course/:courseId" element={<EnrolledCoursePage />} />
   */}
  <Route key="course-lesson" path="/course/:courseId/:moduleId/:lessonId" element={<CourseVideoPage />} />
  <Route key="quiz-result" path="/quizResult" element={<Comingsoon />} />
  <Route key="mentors" path="/mentors" element={<MentorsExplorePage />} />
  <Route key="mentor-programme" path="/mentors/mentoringprogramme/:menteeId" element={<MentorProgrammePage />} />
  <Route key="mentor-programme-no-mentee" path="/mentors/mentoringprogramme" element={<MentorProgrammePage />} />
  <Route key="mentor-request" path="/mentors/mentor-request" element={<ExploreComponent />} />
  <Route key="mentor-feedback" path="/mentors/mentorprogammepage/sessionfeedbackform" element={<MentoringFeedbackForm />} />
  <Route key="mentor-platform" path="/mentors/mentor-platform" element={<MentorRequestsPage />} />
  <Route key="communities" path="/communities" element={<Comingsoon />} />
  <Route key="create-community" path="/createcommunity" element={<Comingsoon />} />
  <Route key="community-name" path="/communities/c/:communityName" element={<Comingsoon />} />
  <Route key="community-post" path="/communities/c/:communityName/post/:postId" element={<Comingsoon />} />
  <Route key="news-article" path="/news/:articleId" element={<NewsArticle />} />
  <Route key="news" path="/news" element={<News />} />
  <Route key="admin" path="/admin" element={<AdminHomepage />} />
  
  {educoPageRoutes.map((route) => (
    <Route
      key={`educo-${route.key}`}
      path={`/${route.route}`}
      element={<VideoPage name={`${route.title}`} />}
    />
  ))}

  <Route key="admin-portal" path='/admin/OfficerPortal' element={<AdminHomepage />} />
  <Route key="email-footer" path='/admin/EmailFooterGenerator' element={<EmailFooterGenerator />} />
  <Route key="studio" path="/${}" element={<StudioDashboard />} />
  <Route key="not-found" path="*" element={<NotFound />} /> 
</Routes>
  );
};

export default SignedInRoutePages;




{/*
      <Route path="/bulkuploader" element={<BulkUploader />} />
        {communitiesPageRoutes.map((route) => (
          <Route
            key={route.key}
            path={route.path}
            element={<CommunitiesPage name={route.title} />}
          />
        ))}
        {coursePageRoutes.map((route) => (
          <Route
            key={route.key}
            path={route.path}
            element={<CoursePage name={route.title} />}
          />
        ))}
        {mentorsPageRoutes.map((route) => (
          <Route
            key={route.key}
            path={route.path}
            element={<MentorPage name={route.title} />}
          />
        ))}
        */
      }

      {/* element=<MentoringFeedbackForm */}

      {/* <Route path="/communities" element={<CommunitiesExplorePage />} />
      <Route
          path="/createcommunity"
          element={<CreateCommunity onCreate={handleCreateCommunity} />}
        />
        <Route path="/communities/c/:communityName" element={<CommunitiesExplorePage />} />
        <Route path="/communities/c/:communityName/post/:postId" element={<CommunitiesExplorePage />} /> */}

        {/*
      <Route path="/calendar" element={<Calendar />} />
        Statements Directory
      <Route path="/loading" element={<LoadingComponent isLoading={true} />} />
      */}