import { useState } from "react";
import { useHMSActions, selectIsConnectedToRoom, useHMSStore } from "@100mslive/react-sdk";
import { functions } from "../../../../../../Firebase/firebaseConfig";
import { httpsCallable } from "firebase/functions";
import useAuth from "../../../../../../Firebase/Auth";
import { emailsHasher } from "../../../../../../Firebase/educoFunctions";
import VideoCall from "../VideoCall/VideoCall";

function JoinForm({userType, onJoinSuccess, onJoiningStart, onJoiningEnd}) {
  const currentUser = useAuth();
  const hmsActions = useHMSActions();
  const isConnected = useHMSStore(selectIsConnectedToRoom);
  const [inputValues, setInputValues] = useState({
    name: "",
    roomCode: ""
  });
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [authToken, setAuthToken] = useState(null);
  const [hasJoined, setHasJoined] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError("");
    onJoiningStart();
    if (hasJoined || isLoading) return;
    const emailHash = emailsHasher(currentUser.email);
    console.log(emailHash, userType)

    
    try {
      const emailHash = emailsHasher(currentUser.email);
      const generate100msToken = httpsCallable(functions, 'generate_100ms_token');
      
      const result = await generate100msToken({
        userId: String(emailHash),
        roomId: inputValues.roomCode,
        role: userType,
        templateId: '679210304b6eb78daeed2820'
      });

      if (result.data.success) {
        await hmsActions.join({
          userName: inputValues.name,
          authToken: result.data.authToken
        });
        onJoinSuccess(result.data.authToken); // Pass token to parent
      } else {
        throw new Error(result.data?.message || 'Failed to get token');
      }
    } catch (error) {
      console.error('Error joining room:', error);
      setError(error.message || 'Failed to join room');
    } finally {
      setIsLoading(false);
      onJoiningEnd(); // Notify parent loading finished
    }
  };

  const handleLeave = async () => {
    try {
      await hmsActions.leave();
      setHasJoined(false); // Reset join state
      setAuthToken(null);
    } catch (error) {
      console.error('Error leaving room:', error);
    }
  };

  // If connected, show the video call interface
  if (authToken) {
    return <VideoCall authToken={authToken} onLeave={handleLeave} />;
  }

  const handleInputChange = (e) => {
    setInputValues((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  const styles = {
    form: {
      display: 'flex',
      flexDirection: 'column',
      gap: '1.5rem',
      padding: '1rem',
      width: '100%',
      maxWidth: '400px'
    },
    title: {
      color: '#1F484C',
      fontSize: '1.75rem',
      fontWeight: '600',
      marginBottom: '0.5rem',
      textAlign: 'center'
    },
    inputContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '0.5rem',
      width: '100%',
      padding: '0 1rem'
    },
    input: {
      padding: '0.75rem 1rem',
      border: '1px solid #E5E7EB',
      borderRadius: '0.375rem',
      fontSize: '1rem',
      width: '100%',
      transition: 'border-color 0.2s ease',
      outline: 'none',
      backgroundColor: '#FFFFFF'
    },
    button: {
      backgroundColor: '#1F484C',
      color: 'white',
      padding: '0.75rem',
      borderRadius: '0.375rem',
      fontSize: '1rem',
      cursor: 'pointer',
      border: 'none',
      transition: 'all 0.2s ease',
      marginTop: '0.5rem',
      width: 'calc(100% - 2rem)',
      margin: '0 1rem'
    },
    error: {
      color: '#DC2626',
      fontSize: '0.875rem',
      textAlign: 'center',
      padding: '0 1rem'
    },
    loading: {
      opacity: 0.7,
      pointerEvents: 'none'
    }
  };

  return (
    <form onSubmit={handleSubmit} style={styles.form}>
      <h2 style={styles.title}>Join Video Call</h2>
      
      <div style={styles.inputContainer}>
        <input
          required
          value={inputValues.name}
          onChange={handleInputChange}
          id="name"
          type="text"
          name="name"
          placeholder="Your name"
          style={styles.input}
          disabled={isLoading}
          onFocus={e => e.target.style.borderColor = '#2C666B'}
          onBlur={e => e.target.style.borderColor = '#E5E7EB'}
        />
      </div>

      <div style={styles.inputContainer}>
        <input
          required
          value={inputValues.roomCode}
          onChange={handleInputChange}
          id="room-code"
          type="text"
          name="roomCode"
          placeholder="Room code"
          style={styles.input}
          disabled={isLoading}
          onFocus={e => e.target.style.borderColor = '#2C666B'}
          onBlur={e => e.target.style.borderColor = '#E5E7EB'}
        />
      </div>

      {error && <div style={styles.error}>{error}</div>}

      <button 
        type="submit"
        disabled={isLoading || hasJoined}
        style={{
          ...styles.button,
          ...(isLoading ? styles.loading : {})
        }}
        onMouseEnter={e => !isLoading && (e.target.style.backgroundColor = '#2C666B')}
        onMouseLeave={e => !isLoading && (e.target.style.backgroundColor = '#1F484C')}
      >
        {hasJoined ? 'Joined' : isLoading ? 'Joining...' : 'Join Call'}
      </button>
    </form>
  );
}

export default JoinForm;