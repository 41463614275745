// JoinCallButton.jsx
import React, { useState } from 'react';
import { FaVideo, FaTimes, FaSpinner } from 'react-icons/fa';
import JoinForm from '../JoinForm/JoinForm';
import VideoCall from '../VideoCall/VideoCall';
import { motion } from 'framer-motion';

const JoinCallButton = ({ windowWidth, userType }) => {
  const [showFormModal, setShowFormModal] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [authToken, setAuthToken] = useState(null);
  const [isJoining, setIsJoining] = useState(false);

  // Form modal styles (400px)
  const formModalStyles = {
    modal: {
      position: 'fixed',
      inset: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 1000
    },
    content: {
      backgroundColor: 'white',
      borderRadius: '12px',
      padding: '2rem',
      position: 'relative',
      width: '90%',
      maxWidth: '500px'
    }
  };

  // Video modal styles (1300px)
  const videoModalStyles = {
    modal: {
      position: 'fixed',
      inset: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 1000
    },
    content: {
      backgroundColor: 'black',
      borderRadius: '12px',
      position: 'relative',
      width: '95%',
      maxWidth: '1300px',
      minHeight: '600px'
    }
  };

  const handleJoinSuccess = (token) => {
    setAuthToken(token);
    setIsJoining(false);
    setShowFormModal(false);  // Close form modal
    setShowVideoModal(true);  // Open video modal
  };

  const handleCloseVideo = () => {
    setShowVideoModal(false);
    setAuthToken(null);
  };

  return (
    <>
      {/* Join Call Button */}
      <motion.button  
      style={{
        padding: '10px 20px',
        fontSize: '16px',
        fontWeight: '500',
        borderRadius: '24px',
        cursor: 'pointer',
        transition: 'all 0.3s',
        minWidth: '120px',
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        backgroundColor: '#E79B18',
        color: '#FFF',      
        border: '1px solid #e0e0e0',
        cursor: 'pointer',
      }}
      whileHover={{ scale: 1.03 }}
      whileTap={{ scale: 0.98 }}
      onClick={() => setShowFormModal(true)}>
        <FaVideo />
        {windowWidth <= 640 ? 'Join Call' : 'Join Video Call'}
      </motion.button>

      {/* Form Modal */}
      {showFormModal && (
        <div style={formModalStyles.modal}>
          <div style={formModalStyles.content}>
            <button 
              onClick={() => setShowFormModal(false)}
              style={{ position: 'absolute', right: '1rem', top: '1rem' }}
            >
              <FaTimes />
            </button>
            
            {isJoining ? (
              <div style={{ textAlign: 'center', padding: '2rem' }}>
                <FaSpinner className="spin" />
                <p>Joining call...</p>
              </div>
            ) : (
              <JoinForm 
                userType={userType}
                onJoinSuccess={handleJoinSuccess}
                onJoiningStart={() => setIsJoining(true)}
                onJoiningEnd={() => setIsJoining(false)}
              />
            )}
          </div>
        </div>
      )}

      {/* Video Call Modal */}
      {showVideoModal && (
        <div style={videoModalStyles.modal}>
          <div style={videoModalStyles.content}>
            <button 
              onClick={handleCloseVideo}
              style={{ 
                position: 'absolute',
                right: '1rem',
                top: '1rem',
                color: 'white',
                zIndex: 1001
              }}
            >
              <FaTimes />
            </button>
            
            {authToken && <VideoCall authToken={authToken} />}
          </div>
        </div>
      )}
    </>
  );
};

export default JoinCallButton;